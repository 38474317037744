function needsTabIndex(el: Element): boolean {
  return !el.hasAttribute('tabindex') && !['A', 'BUTTON', 'INPUT', 'TEXTAREA', 'SELECT'].includes(el.tagName);
}

function focusTargetElementsOnClick(): void {
  const links = document.querySelectorAll('a[href^="#"]');

  links.forEach((link) => {
    link.addEventListener('click', (event) => {
      const targetId = link.getAttribute('href')?.replace('#', '');

      if (!targetId) {
        return;
      }

      event.preventDefault();

      const targetElement = document.getElementById(targetId);
      if (!targetElement) {
        return;
      }

      if (needsTabIndex(targetElement)) {
        targetElement.setAttribute('tabindex', '-1');
      }

      targetElement.focus();
    });
  });
}

export function initAnchorLinksModule() {
  focusTargetElementsOnClick();
}
