import { notifyHandledException } from './util/error-handling';

export const initCategorySwiper = function () {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  import('swiper/swiper-bundle.esm.browser')
    .then(({ default: Swiper }) => {
      new Swiper('.swiper-category-icons', {
        slidesPerView: 2.5,
        spaceBetween: 10,
        centeredSlides: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        loop: true,
        autoHeight: true,
        breakpoints: {
          700: {
            centeredSlides: false,
            slidesPerView: 4,
          },
          1180: {
            centeredSlides: false,
            slidesPerView: 5,
          },
          1440: {
            centeredSlides: false,
            slidesPerView: 6,
          },
          1500: {
            slidesPerView: 7,
            centeredSlides: false,
          },
        },
      });
    })
    .catch((error) => {
      notifyHandledException(error, true);
    });
};
