import { notifyHandledException } from '../../util/error-handling';
export function initConclusionBlockSlider(): void {
  if (!document.querySelector('.conclusion-block-swiper')) {
    return;
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  import('swiper/swiper-bundle.esm.browser')
    .then(({ default: Swiper }) => {
      new Swiper('.conclusion-block-swiper', {
        slidesPerView: 1.2,
        spaceBetween: 10,
        centeredSlides: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        loop: true,
        autoHeight: true,
        breakpoints: {
          768: {
            slidesPerView: 2.2,
            spaceBetween: 10,
          },
          1024: {
            slidesPerView: 3.4,
            spaceBetween: 15,
            centeredSlides: true,
          },
        },
      });
    })
    .catch((error) => {
      notifyHandledException(error, true);
    });
}
